<template>
  <div>
    <page-header-wrapper :title="false">
    </page-header-wrapper>

  </div>
</template>

<script>
import {
  ChartCard,
  Trend
} from '@/components'
export default {
  components: {
    ChartCard,
    Trend
  },
  data () {
    return {
      loading: false,
      flag: {}
    }
  },
  created () {

  }
}
</script>
<style lang="less" scoped>
@import "./Workplace.less";
</style>
